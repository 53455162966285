import {SiteStore, SelectedShippingOption} from '@wix/wixstores-client-storefront-sdk';
import {DestinationCompletionListService} from '../services/DestinationCompletionListService';
import {DestinationCompletion} from '@wix/wixstores-graphql-schema-node';
import {ShippingRuleStatus} from '@wix/wixstores-client-core';
import {BIService} from '../services/BIService';
import {StyleSettingsService} from '../services/StyleSettingsService';
import _ from 'lodash';
import {CartService} from '../services/CartService';
import {ICartControllerApi} from '../../types/app.types';
import {OrderService} from '../services/OrderService';
import {ModalManagerService} from '../services/ModalManagerService';
import {getCatalogAppIds} from '../utils/bi.utils';
import {SPECS} from '../specs';

type OrderStoreConfig = {
  cartService: CartService;
  orderService: OrderService;
  biService: BIService;
  styleSettingsService: StyleSettingsService;
  modalManagerService: ModalManagerService;
};

export enum DestinationCompleteness {
  COUNTRY = 'COUNTRY',
  SUBDIVISION = 'SUBDIVISION',
  ZIPCODE = 'ZIPCODE',
}

export class OrderStore {
  private destinationCompletionList: DestinationCompletion[];
  private readonly biService: BIService;
  private readonly cartService: CartService;
  private readonly controllerApi: ICartControllerApi;
  private readonly destinationCompletionListService: DestinationCompletionListService;
  private readonly modalManagerService: ModalManagerService;
  private readonly orderService: OrderService;
  private readonly siteStore: SiteStore;
  private readonly styleSettingsService: StyleSettingsService;
  private readonly taxName: string;
  private readonly shouldNotUseDestinationCompleteness: boolean;

  constructor(
    controllerApi: ICartControllerApi,
    siteStore: SiteStore,
    {biService, styleSettingsService, cartService, orderService, modalManagerService}: OrderStoreConfig
  ) {
    this.controllerApi = controllerApi;
    this.siteStore = siteStore;
    this.destinationCompletionListService = new DestinationCompletionListService({siteStore});
    this.styleSettingsService = styleSettingsService;
    this.biService = biService;
    this.cartService = cartService;
    this.orderService = orderService;
    this.taxName = this.siteStore.taxName;
    this.modalManagerService = modalManagerService;
    this.shouldNotUseDestinationCompleteness = this.siteStore.experiments.enabled(
      SPECS.ShouldNotUseDestinationCompleteness
    );

    if (!this.shouldNotUseDestinationCompleteness) {
      //eslint-disable-next-line @typescript-eslint/no-floating-promises
      this.fetchDestinationCompletionList();
    }
  }

  private async fetchDestinationCompletionList() {
    const {shouldShowShipping, shouldShowTax} = this.styleSettingsService;

    this.destinationCompletionList = await this.destinationCompletionListService.fetch({
      forShipping: shouldShowShipping,
      forTax: shouldShowTax,
    });
  }

  private get hasTax(): boolean {
    return !!this.cartService.estimatedTotals?.priceSummary.tax.convertedAmount;
  }

  private shouldDisplaySubtotalForDigitalCart() {
    const {shouldShowTax} = this.styleSettingsService;
    const appliedCoupon = this.cartService.estimatedTotals?.appliedCoupon || this.cartService.cartModel.appliedCoupon;

    return (
      shouldShowTax &&
      (!this.orderService.taxOnProduct || (this.orderService.taxOnProduct && appliedCoupon)) &&
      this.orderService.isEstimatedDestinationIsShippable &&
      this.hasTax
    );
  }

  private shouldDisplaySubtotalForMixedCart() {
    if (!this.cartService.estimatedTotals) {
      return false;
    }
    const {shouldShowTax, shouldShowShipping} = this.styleSettingsService;

    return (shouldShowTax && !this.orderService.taxOnProduct) || shouldShowShipping;
  }

  private get shouldDisplaySubtotal() {
    // full logic https://p5r73x.axshare.com/#p=shipping_and_tax_show_hide
    if (this.shouldDisplayAdditionalFees) {
      return true;
    }

    if (this.cartService.isNonShippableCart) {
      return this.shouldDisplaySubtotalForDigitalCart();
    }

    return this.shouldDisplaySubtotalForMixedCart();
  }

  private get shouldDisplaySubtotalTitle(): boolean {
    //full logic explained here: https://p5r73x.axshare.com/#p=shipping_and_tax_show_hide
    const {isNonShippableCart} = this.cartService;
    const {shouldShowTax, shouldShowShipping} = this.styleSettingsService;

    if (this.shouldDisplayAdditionalFees) {
      return false;
    }

    const digitalWithDefinedTax =
      isNonShippableCart && shouldShowTax && this.orderService.hasEstimatedDestination && this.hasTax;
    const mixedWithShippingOrTax =
      !isNonShippableCart && (shouldShowShipping || (shouldShowTax && !this.orderService.taxOnProduct));

    return !(digitalWithDefinedTax || mixedWithShippingOrTax);
  }

  private get shouldDisplayEstimatedTaxShippingDestination() {
    const {shouldShowShipping} = this.styleSettingsService;

    return !shouldShowShipping && !this.cartService.isNonShippableCart;
  }

  private get hasShippingDestination() {
    return this.orderService.hasShippingDestination;
  }

  public get shouldDisplayDelivery() {
    const {shouldShowShipping} = this.styleSettingsService;

    return shouldShowShipping && !this.cartService.isNonShippableCart;
  }

  private readonly handleChangeRegion = async () => {
    if (this.siteStore.isEditorMode()) {
      return;
    }

    const cartModel = this.cartService.cartModel;
    const modalData: any = {
      country: cartModel.contactInfo?.address.country,
      subdivision: cartModel.contactInfo?.address.subdivision,
      zipCode: cartModel.contactInfo?.address.postalCode,
      shouldNotUseDestinationCompleteness: this.shouldNotUseDestinationCompleteness,
    };
    modalData.biData = {
      cartId: cartModel.id,
      cartType: this.cartService.cartType,
      catalogAppId: getCatalogAppIds(cartModel),
      checkoutId: cartModel.checkoutId,
      purchaseFlowId: cartModel.purchaseFlowId,
    };

    if (!this.shouldNotUseDestinationCompleteness) {
      modalData.destinationCompletionList = this.destinationCompletionList
        ? this.destinationCompletionList.map(
            /* istanbul ignore next */ (destination) =>
              /* istanbul ignore next */
              _.pick(destination, ['country', 'subregions', 'subdivisions'])
          )
        : /* istanbul ignore next */
          [];
    }

    this.biService.clickOnShippingDestinationInCartPageSf(this.cartService.cartModel, this.cartService.cartType);

    /* istanbul ignore next */
    const data = ((await this.modalManagerService.modalManger.openChangeRegion(modalData)) as any)?.message;

    if (data?.country) {
      this.biService.updateClickedInSelectYourShippingDestinationPopupSf(
        this.cartService.cartModel,
        this.cartService.estimatedTotals,
        this.cartService.cartType,
        data
      );

      this.orderService.destinationWasChanged = true;

      await this.controllerApi.executeWithLoader(() =>
        this.cartService.setDestinationForEstimation(data, this.cartService.cartModel.id)
      );

      if (!this.orderService.canShipToDestination) {
        this.biService.errorInCheckoutSf(cartModel);
      }
    } else {
      this.biService.cancelClickedInSelectYourShippingDestinationPopupSf(cartModel, this.cartService.cartType);
    }
  };

  private get isFullAddressRequired() {
    return this.cartService.isFullAddressRequired;
  }

  private get shouldDisplayDeliveryError() {
    return (
      this.shouldDisplayDelivery &&
      !this.orderService.canShip &&
      this.orderService.destinationWasChanged &&
      !this.cartService.isEmpty &&
      !this.orderService.hasShippingRules &&
      this.cartService.cartModel.contactInfo &&
      !!this.cartService.cartModel.contactInfo.address.country
    );
  }

  private get shouldDisplayAdditionalFees() {
    if (!this.cartService.estimatedTotals) {
      return false;
    }
    return this.cartService.estimatedTotals.additionalFees.length > 0;
  }

  private get shouldDisplayPayNowPayLater() {
    if (!this.cartService.estimatedTotals) {
      return false;
    }
    return this.cartService.estimatedTotals.payLater.total.convertedAmount > 0;
  }

  private get shouldDisplayDeliveryMethodSelection() {
    const shippingRuleInfo = this.cartService.estimatedTotals?.shippingInfo;

    if (!shippingRuleInfo?.shippingRule) {
      return false;
    }

    const hasShippingRuleOptionWithTimeSlots = shippingRuleInfo.shippingRule.shippingOptions.some(
      (option) => !!option.logistics.deliveryTimeSlot
    );

    const shippingRuleOptions = this.orderService.shippingRuleOptions;
    const isMoreThanOneShippingRuleOption = shippingRuleOptions.length > 1;
    const hasPickupOption = this.orderService.hasPickupOption;

    return (
      this.shouldDisplayDelivery &&
      !hasShippingRuleOptionWithTimeSlots &&
      (isMoreThanOneShippingRuleOption || hasPickupOption)
    );
  }

  private get shouldHideCountryPicker() {
    const shippingRuleInfo = this.cartService.estimatedTotals?.shippingInfo;

    if (!shippingRuleInfo?.shippingRule) {
      return false;
    }

    return shippingRuleInfo.shippingRule.shippingOptions.some((option) => !!option.logistics.deliveryTimeSlot);
  }

  private readonly selectShippingOption = (selectedShippingOptionId: string): Promise<void> => {
    const setShippingOptionData: SelectedShippingOption = {
      shippingRuleId: this.cartService.estimatedTotals.shippingInfo.shippingRule.id,
      optionId: selectedShippingOptionId,
    };
    const fn = () => this.cartService.setShippingOption(this.cartService.cartModel.id, setShippingOptionData);
    return this.controllerApi.executeWithLoader(fn);
  };

  private get isZipCodeValid(): boolean {
    return (
      this.siteStore.experiments.enabled(SPECS.ShouldNotUseDestinationCompleteness) ||
      this.cartService.estimatedTotals?.shippingInfo?.status !== ShippingRuleStatus.MissingZipCode
    );
  }

  private get isMainCurrency(): boolean {
    const {currencyCode, convertedCurrencyCode} = this.cartService.cartModel;

    return !convertedCurrencyCode || currencyCode === convertedCurrencyCode;
  }

  private get isPickup(): boolean {
    return this.orderService.isPickup();
  }

  private get isPickupPoint(): boolean {
    return this.orderService.isPickupPoint;
  }

  public toProps() {
    if (this.cartService.isEmpty) {
      return;
    }

    return {
      isPickup: this.isPickup,
      isPickupPoint: this.isPickupPoint,
      handleChangeRegion: this.handleChangeRegion,
      hasShippingDestination: this.hasShippingDestination,
      isEstimatedDestinationIsShippable: this.orderService.isEstimatedDestinationIsShippable,
      isZipCodeValid: this.isZipCodeValid,
      selectShippingOption: this.selectShippingOption,
      shippingRuleOptions: this.orderService.shippingRuleOptions,
      shouldDisplayDelivery: this.shouldDisplayDelivery,
      shouldDisplayDeliveryError: this.shouldDisplayDeliveryError,
      shouldDisplayDeliveryMethodSelection: this.shouldDisplayDeliveryMethodSelection,
      shouldDisplayEstimatedTaxShippingDestination: this.shouldDisplayEstimatedTaxShippingDestination,
      shouldDisplaySubtotal: this.shouldDisplaySubtotal,
      shouldDisplaySubtotalTitle: this.shouldDisplaySubtotalTitle,
      shouldDisplayAdditionalFees: this.shouldDisplayAdditionalFees,
      shouldDisplayPayNowPayLater: this.shouldDisplayPayNowPayLater,
      shouldDisplayTax: this.orderService.shouldDisplayTax,
      shouldShowTax: this.styleSettingsService.shouldShowTax,
      shouldShowShipping: this.styleSettingsService.shouldShowShipping,
      shouldHideCountryPicker: this.shouldHideCountryPicker,
      taxName: this.taxName,
      taxOnProduct: this.orderService.taxOnProduct,
      isMainCurrency: this.isMainCurrency,
      isFullAddressRequired: this.isFullAddressRequired,
    };
  }
}
